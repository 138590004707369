<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.choose.click">选择模版样式</en-button>
      <en-button type="primary" @click="operation.add.click">添加模版</en-button>
    </template>

    <div class="flex items-center gap-6">
      <span>是否开启打印设置</span>
      <en-switch v-model="enabled.data" active-value="Y" inactive-value="N" active-text="开" inactive-text="关"></en-switch>
    </div>

    <tabs-maintain
      v-model="tabs.active"
      :ajax="{
        action: 'GET /enocloud/common/lookup/:lookupType',
        params: (params) => (params.paths = ['RPTTPLTPE'])
      }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :loading="table.loading" pagination :paging="table.paging">
          <en-table-column label="模板名称" prop="name">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['ReportTemplateDto'] }">
              <button-ajax link :method="table.name.click" :params="row">{{ row.name }}</button-ajax>
            </template>
          </en-table-column>
          <en-table-column label="模板类型" prop="type.message"></en-table-column>
          <en-table-column label="状态" prop="status.message"></en-table-column>
          <en-table-column label="排序" prop="seq"></en-table-column>
          <en-table-column label="备注" prop="comment"></en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑' : '添加' + '模版'" direction="btt" size="80%">
    <en-form
      :model="detail.form.data"
      :rules="detail.form.rules"
      :loading="detail.form.loading"
      :ref="setRef('detailForm')"
      class="grid grid-cols-5 gap-6"
    >
      <en-form-item label="报表类型" prop="type.code">
        <select-maintain
          v-model="detail.form.data.type"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['RPTTPLTPE'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="模板名称" prop="name">
        <en-input v-model="detail.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="状态" prop="status.code">
        <select-maintain
          v-model="detail.form.data.status"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['RCSTAT'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          disabled
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="排序">
        <en-input-number v-model="detail.form.data.seq" :min="0" :precision="0" :step="1" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="detail.form.data.comment" type="textarea" maxlength="200" show-word-limit></en-input>
      </en-form-item>
    </en-form>

    <print-template-grid :template-type="detail.form.data.type?.templateType" :data="detail.form.data.reportTemplateFields"></print-template-grid>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax type="primary" :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { PrintTemplateGrid } from '@enocloud/business'

export default factory({
  components: { PrintTemplateGrid },

  config: {
    children: {
      operation: {
        choose: {
          click() {}
        },
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      enabled: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/report/template/traditional/enabled',
            data: 'string'
          },
          submit: {
            action: 'PUT /enocloud/common/report/template/traditional/enabled'
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/report/template',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { type: this.tabs.active, readonly: 'N' }
            }
          }
        },
        children: {
          name: {
            async click(row: EnocloudCommonDefinitions['ReportTemplateDto']) {
              this.detail.form.init()
              this.detail.form.data.id = row.id
              await this.detail.form.get()
              return (this.detail.visible = true)
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/report/template/:reportTemplateId',
                data: 'object',
                init: true,
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/report/template',
                loading: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/report/template',
                loading: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请填写模版名称' }]
            }
          },
          footer: {
            children: {
              cancel: {
                click() {
                  this.detail.visible = false
                }
              },
              confirm: {
                async click() {
                  await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                  return this.table.get().then(() => (this.detail.visible = false))
                }
              }
            }
          }
        }
      }
    }
  },
  async mounted() {
    this.table.get()
    this.enabled.get()
    this.detail.form.data.id = 186
    await this.detail.form.get()
    this.detail.visible = true
  }
})
</script>
